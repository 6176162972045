<template>
	<div class="groupManage">
		<div class="table">
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item label="内容类型" required>
					<el-radio-group v-model="form.type" @input="changeType">
						<el-radio label="1">文字</el-radio>
						<el-radio label="2">图片</el-radio>
						<el-radio label="4">视频</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="发送文本" v-if="form.type == 1">
					<el-input type="textarea" v-model="form.content" placeholder="请输入发送内容" maxlength="200" rows="5"
						show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="发送图片" v-if="form.type == 2">
					<el-upload class="avatar-uploader" :action="serverUrl" v-loading="uploading"
						:on-progress="handleAvatarProcess" :headers="uploadHeaders" :data="uploadParam"
						:show-file-list="false" :on-success="handleAvatarSuccess">
						<img v-if="form.pic" :src="form.pic" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="发送视频" v-if="form.type == 4">
					<el-upload class="avatar-uploader" :action="serverUrl" v-loading="uploading"
						:on-progress="handleAvatarProcess" :headers="uploadHeaders" :data="uploadParam"
						:show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeUploadVideo">
						<video controls v-if="form.video" :src="form.video" class="avatar"></video>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="好友：" v-if="wxUserList.length > 0" required>
					<el-checkbox-group v-model="form.target">
						<el-checkbox v-for="item in wxUserList" :key="item.id" :label="item.id">{{ item.nickname
                   }}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item label="群组：" v-if="wxGroupList.length > 0" required>
					<el-checkbox-group v-model="form.target">
						<el-checkbox v-for="item in wxGroupList" :key="item.id" :label="item.id">{{ item.nickname
                   }}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmFn">确 定</el-button>
			</span>
		</div>
	</div>
</template>

<script>
	import CryptoJS from 'crypto-js'
	var timestamp = new Date().getTime()
	export default {
		data() {
			return {
				searchForm: {},
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				wxList: [],
				wxUserList: [],
				wxGroupList: [],
				form: {
					type: "1",
					target: []
				},
				//上传相关
				uploadParam: {

				},
				uploadHeaders: {
					token: window.sessionStorage.getItem('token'),
					sign: CryptoJS.MD5(sessionStorage.getItem('token') + 'w2MDYDE8nx4CQdA8fn2Nmnexn4bSyBbh' + timestamp)
						.toString(),
					timestamp: timestamp
				},
				serverUrl: 'erp/v1/common/upload',
				uploading: false
			}
		},
		mounted() {
			this.getWxAll()
		},
		methods: {
			// 确定
			confirmFn() {
				if (this.form.type == 2) {
					this.form.content = this.form.pic
				}
				if (this.form.type == 4) {
					this.form.content = this.form.video
				}
				if (!this.form.content) {
					this.$message.error('请输入发送内容');
					return
				}
				if (this.form.target.length <= 0) {
					this.$message.error('请选择群组/好友');
					return
				}
				this.form.target = this.form.target.toString()
				let url = '/erp/v1/wx_task/push'
				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);

						window.location.reload()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 微信群组
			getWxAll() {
				this.wxUserList = []
				this.wxGroupList = []
				this.$http.post(`/erp/v1/wx_groups/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.wxList = data
						this.wxList.forEach(item => {
							if (item.type == 1) {
								this.wxUserList.push(item)
							} else {
								this.wxGroupList.push(item)
							}
						})
						console.log(this.wxUserList, 'this.wxUserList');
						console.log(this.wxGroupList, 'this.wxGroupList');
					} else {
						this.$message.error(msg);
					}
				});
			},
			changeType(val) {
				if (val < 4) {
					this.$set(this.uploadParam, "type", 1)
					this.uploadParam.type == "1"
				} else {
					this.$set(this.uploadParam, "type", 4)
					this.uploadParam.type == "4"
				}
			},
			handleAvatarSuccess(res, file) {
				this.uploading = false
				if (this.form.type == 2) {
					this.form.pic = res.data.path
				} else {
					this.form.video = res.data.path
				}

				this.$forceUpdate();
			},
			handleAvatarProcess(res, file) {

				this.uploading = true
				//this.$forceUpdate();
			},
			beforeUploadVideo(file) {
				let isLt25M = file.size / 1024 / 1024 < 25; // 小于25MB
				if (!isLt25M) {
					this.$message.error('上传的文件大小不能超过 25MB!');
				}
				return isLt25M;
			}
		},
	}
</script>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #FF720E;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #FF720E;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 125PX;
		height: 125PX;
		line-height: 125PX;
		text-align: center;
	}

	.avatar {
		width: 125PX;
		height: 125PX;
		display: block;
	}
</style>